import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

const CursorEffect = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [cursorVariant, setCursorVariant] = useState('default');

  useEffect(() => {
    const mouseMove = (e) => {
      setMousePosition({
        x: e.clientX,
        y: e.clientY
      });
    };

    window.addEventListener('mousemove', mouseMove);

    return () => {
      window.removeEventListener('mousemove', mouseMove);
    };
  }, []);

  const variants = {
    default: {
      x: mousePosition.x - 16,
      y: mousePosition.y - 16,
      transition: {
        type: "spring",
        mass: 0.6
      }
    },
    hover: {
      x: mousePosition.x - 32,
      y: mousePosition.y - 32,
      scale: 2,
      transition: {
        type: "spring",
        mass: 0.6
      }
    }
  };

  useEffect(() => {
    const handleHover = () => setCursorVariant('hover');
    const handleLeave = () => setCursorVariant('default');

    const elements = document.querySelectorAll('button, a, .hover-effect');
    elements.forEach(el => {
      el.addEventListener('mouseenter', handleHover);
      el.addEventListener('mouseleave', handleLeave);
    });

    return () => {
      elements.forEach(el => {
        el.removeEventListener('mouseenter', handleHover);
        el.removeEventListener('mouseleave', handleLeave);
      });
    };
  }, []);

  return (
    <>
      <motion.div
        className="cursor-dot"
        variants={variants}
        animate={cursorVariant}
        style={{
          position: 'fixed',
          left: 0,
          top: 0,
          width: 32,
          height: 32,
          backgroundColor: 'transparent',
          borderRadius: '50%',
          border: '2px solid #00f6ff',
          pointerEvents: 'none',
          zIndex: 9999,
          mixBlendMode: 'difference'
        }}
      />
      <motion.div
        className="cursor-ring"
        animate={{
          x: mousePosition.x - 75,
          y: mousePosition.y - 75,
        }}
        transition={{
          type: "spring",
          mass: 1,
          damping: 20
        }}
        style={{
          position: 'fixed',
          left: 0,
          top: 0,
          width: 150,
          height: 150,
          border: '1px solid rgba(0, 246, 255, 0.2)',
          borderRadius: '50%',
          pointerEvents: 'none',
          zIndex: 9998,
        }}
      />
      <div
        className="cursor-trail"
        style={{
          position: 'fixed',
          left: mousePosition.x,
          top: mousePosition.y,
          width: 4,
          height: 4,
          backgroundColor: '#00f6ff',
          borderRadius: '50%',
          pointerEvents: 'none',
          zIndex: 9997,
          boxShadow: '0 0 20px #00f6ff',
        }}
      />
    </>
  );
};

export default CursorEffect;
