import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebase';
import { Email as EmailIcon } from '@mui/icons-material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSnackbarMessage('');
    setSnackbarSeverity('success');
    setSnackbarOpen(false);
    try {
      await sendPasswordResetEmail(auth, email);
      setSnackbarMessage('重置密码链接已发送到您的邮箱，若没找到可查看垃圾邮件');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setTimeout(() => navigate('/login'), 2000);
    } catch (error) {
      const errorMessage = error.message;
      console.error('Error:', errorMessage);
      setSnackbarMessage('发送失败: ' + errorMessage);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-cyber-dark text-white">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="w-full max-w-md space-y-8 p-8 rounded-lg bg-cyber-dark/30 backdrop-blur-lg border border-cyber-purple/20"
        style={{ boxShadow: '0 0 20px rgba(126, 34, 206, 0.3)' }}
      >
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-cyber-blue animate-glow">
            重置密码
          </h2>
          <p className="mt-2 text-center text-sm text-gray-300">
            输入您的邮箱，我们将向您发送重置密码的链接
          </p>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div className="rounded-md shadow-sm">
            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-medium text-cyber-blue mb-2">
                电子邮件地址
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="appearance-none relative block w-full px-3 py-2 border rounded-lg placeholder-gray-500 focus:outline-none focus:ring-cyber-blue focus:border-cyber-blue sm:text-sm"
                placeholder="your.email@example.com"
                style={{
                  backgroundColor: '#0a0a0a',
                  color: '#00f6ff',
                  borderColor: '#7e22ce',
                  boxShadow: '0 0 10px #7e22ce',
                }}
              />
            </div>
          </div>

          <div>
            <motion.button
              type="submit"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-cyber-purple hover:bg-cyber-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyber-blue transition-colors duration-300"
            >
              发送重置链接
            </motion.button>
          </div>

          <div className="text-sm text-center">
            <Link to="/login" className="font-medium text-cyber-blue hover:text-cyber-purple">
              返回登录
            </Link>
          </div>
        </form>
      </motion.div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ForgotPassword;
