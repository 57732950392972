import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { getAuth, updateProfile, updatePassword, updateEmail, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import {
  Close as CloseIcon,
  PhotoCamera as PhotoCameraIcon,
  Save as SaveIcon,
  Lock as LockIcon,
} from '@mui/icons-material';

const UserSettings = ({ user, onClose }) => {
  const [displayName, setDisplayName] = useState(user?.displayName || '');
  const [email, setEmail] = useState(user?.email || '');
  const [password, setPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [photoURL, setPhotoURL] = useState(user?.photoURL || '');
  const [loading, setLoading] = useState(false);
  const [photoLoading, setPhotoLoading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const auth = getAuth();
  const storage = getStorage();

  useEffect(() => {
    return () => {
      setPhotoLoading(false);
      setLoading(false);
      setError('');
      setMessage('');
    };
  }, []);

  const handlePhotoUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (file.size > 5 * 1024 * 1024) {
      setError('文件大小不能超过 5MB');
      setTimeout(() => setError(''), 3000);
      return;
    }

    if (!file.type.startsWith('image/')) {
      setError('请上传图片文件');
      setTimeout(() => setError(''), 3000);
      return;
    }

    try {
      setPhotoLoading(true);
      setError('');
      setMessage('');

      const fileName = `${user.uid}_${Date.now()}_${file.name}`;
      const storageRef = ref(storage, `userPhotos/${user.uid}/${fileName}`);
      
      await uploadBytes(storageRef, file);
      
      const downloadURL = await getDownloadURL(storageRef);
      
      await updateProfile(auth.currentUser, { photoURL: downloadURL });
      
      setPhotoURL(downloadURL);
      setMessage('头像更新成功！');
      
      setTimeout(() => setMessage(''), 3000);
    } catch (error) {
      console.error('Photo upload error:', error);
      setError('上传头像失败: ' + error.message);
      setTimeout(() => setError(''), 3000);
    } finally {
      setPhotoLoading(false);
    }
  };

  const handleSave = async () => {
    if (loading) return; 

    try {
      setLoading(true);
      setError('');
      setMessage('');

      if ((email !== user.email || password) && !currentPassword) {
        setError('更新邮箱或密码需要验证当前密码');
        return;
      }

      if (email !== user.email || password) {
        try {
          const credential = EmailAuthProvider.credential(user.email, currentPassword);
          await reauthenticateWithCredential(auth.currentUser, credential);
        } catch (error) {
          setError('当前密码验证失败');
          return;
        }
      }

      const updates = [];
      
      if (displayName !== user.displayName) {
        updates.push(updateProfile(auth.currentUser, { displayName }));
      }

      if (email !== user.email) {
        updates.push(updateEmail(auth.currentUser, email));
      }

      if (password) {
        updates.push(updatePassword(auth.currentUser, password));
      }

      if (updates.length === 0) {
        setMessage('没有需要更新的内容');
        return;
      }

      await Promise.all(updates);
      setMessage('用户资料更新成功！');
      setPassword(''); 
      setCurrentPassword(''); 
      setTimeout(() => setMessage(''), 3000); 
    } catch (error) {
      setError('更新失败: ' + error.message);
      setTimeout(() => setError(''), 3000); 
    } finally {
      setLoading(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center"
      onClick={(e) => e.target === e.currentTarget && onClose()}
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className="bg-cyber-dark/95 rounded-lg p-6 max-w-md w-full mx-4 border border-cyber-purple/30"
      >
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-cyber-blue">用户设置</h2>
          <button
            onClick={onClose}
            className="p-1 hover:bg-cyber-purple/20 rounded-full transition-colors"
          >
            <CloseIcon />
          </button>
        </div>

        {/* Photo Upload */}
        <div className="mb-6 flex flex-col items-center">
          <div className="relative">
            <img
              src={photoURL || 'https://via.placeholder.com/100'}
              alt="Profile"
              className="w-24 h-24 rounded-full object-cover border-2 border-cyber-purple/30"
            />
            <label className={`absolute bottom-0 right-0 p-1 ${photoLoading ? 'bg-gray-500' : 'bg-cyber-purple'} rounded-full cursor-pointer transition-colors`}>
              <PhotoCameraIcon fontSize="small" />
              <input
                type="file"
                accept="image/*"
                className="hidden"
                onChange={handlePhotoUpload}
                disabled={photoLoading}
              />
            </label>
          </div>
          {photoLoading && (
            <p className="text-sm text-gray-400 mt-2">上传中...</p>
          )}
        </div>

        {/* Form Fields */}
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              昵称
            </label>
            <input
              type="text"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
              className="w-full p-2 bg-cyber-dark/50 border border-cyber-purple/30 rounded-md text-white"
              disabled={loading}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              邮箱
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-2 bg-cyber-dark/50 border border-cyber-purple/30 rounded-md text-white"
              disabled={loading}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-400 mb-1">
              新密码 (可选)
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-2 bg-cyber-dark/50 border border-cyber-purple/30 rounded-md text-white"
              disabled={loading}
            />
          </div>

          {(email !== user.email || password) && (
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-1">
                当前密码 (必填)
              </label>
              <input
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                className="w-full p-2 bg-cyber-dark/50 border border-cyber-purple/30 rounded-md text-white"
                disabled={loading}
                required
              />
            </div>
          )}

          {error && (
            <div className="text-red-500 text-sm">{error}</div>
          )}

          {message && (
            <div className="text-green-500 text-sm">{message}</div>
          )}

          <div className="flex justify-end pt-4">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleSave}
              disabled={loading || photoLoading}
              className="bg-cyber-purple text-white px-4 py-2 rounded-md flex items-center space-x-2 disabled:opacity-50"
            >
              <SaveIcon fontSize="small" />
              <span>{loading ? '保存中...' : '保存更改'}</span>
            </motion.button>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default UserSettings;
