import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  deleteDoc,
  doc,
  serverTimestamp,
} from 'firebase/firestore';
import {
  AccountCircle as AccountCircleIcon,
  Notifications as NotificationsIcon,
  Settings as SettingsIcon,
  Timeline as TimelineIcon,
  Storage as StorageIcon,
  Speed as SpeedIcon,
  Add as AddIcon,
  Key as KeyIcon,
  History as HistoryIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Logout as LogoutIcon,
} from '@mui/icons-material';
import UserSettings from './UserSettings';

const Dashboard = () => {
  const [user, setUser] = useState(null);
  const [apiKeys, setApiKeys] = useState([]);
  const [timeMachines, setTimeMachines] = useState([]);
  const [showNewApiForm, setShowNewApiForm] = useState(false);
  const [showNewTimeMachineForm, setShowNewTimeMachineForm] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [newApiName, setNewApiName] = useState('');
  const [newTimeMachineName, setNewTimeMachineName] = useState('');
  const [newTimeMachineDesc, setNewTimeMachineDesc] = useState('');
  
  const navigate = useNavigate();
  const auth = getAuth();

  // Format timestamp to readable date string
  const formatTimestamp = (timestamp) => {
    if (!timestamp) return 'N/A';
    if (timestamp.seconds) {
      return new Date(timestamp.seconds * 1000).toLocaleString('zh-CN');
    }
    if (timestamp instanceof Date) {
      return timestamp.toLocaleString('zh-CN');
    }
    return timestamp;
  };

  // Handle logout
  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  // 加载用户数据
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user);
        await loadUserData(user.uid);
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  // 加载用户的 API keys 和时光机数据
  const loadUserData = async (userId) => {
    try {
      // 加载 API keys
      const apiKeysRef = collection(db, 'apiKeys');
      const apiKeysQuery = query(apiKeysRef, where('userId', '==', userId));
      const apiKeysSnapshot = await getDocs(apiKeysQuery);
      const apiKeysData = apiKeysSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setApiKeys(apiKeysData);

      // 加载时光机
      const timeMachinesRef = collection(db, 'timeMachines');
      const timeMachinesQuery = query(timeMachinesRef, where('userId', '==', userId));
      const timeMachinesSnapshot = await getDocs(timeMachinesQuery);
      const timeMachinesData = timeMachinesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setTimeMachines(timeMachinesData);
    } catch (error) {
      console.error('Error loading user data:', error);
    }
  };

  // 创建新的 API key
  const handleCreateApiKey = async () => {
    if (!newApiName.trim() || !user) return;

    try {
      const apiKeysRef = collection(db, 'apiKeys');
      const newKey = {
        name: newApiName,
        key: `tm_${Math.random().toString(36).substr(2, 9)}`,
        created: serverTimestamp(),
        userId: user.uid
      };

      const docRef = await addDoc(apiKeysRef, newKey);
      const newApiKey = {
        id: docRef.id,
        ...newKey,
        created: new Date()
      };

      setApiKeys([...apiKeys, newApiKey]);
      setNewApiName('');
      setShowNewApiForm(false);
    } catch (error) {
      console.error('Error creating API key:', error);
      alert('创建 API key 失败: ' + error.message);
    }
  };

  // 删除 API key
  const handleDeleteApiKey = async (id) => {
    try {
      const apiKeyRef = doc(db, 'apiKeys', id);
      await deleteDoc(apiKeyRef);
      setApiKeys(apiKeys.filter(key => key.id !== id));
    } catch (error) {
      console.error('Error deleting API key:', error);
      alert('删除 API key 失败: ' + error.message);
    }
  };

  // 创建新的时光机
  const handleCreateTimeMachine = async () => {
    if (!newTimeMachineName.trim() || !user) return;

    try {
      const timeMachinesRef = collection(db, 'timeMachines');
      const newMachine = {
        name: newTimeMachineName,
        description: newTimeMachineDesc,
        events: 0,
        lastUpdated: serverTimestamp(),
        userId: user.uid
      };

      const docRef = await addDoc(timeMachinesRef, newMachine);
      const newTimeMachine = {
        id: docRef.id,
        ...newMachine,
        lastUpdated: new Date()
      };

      setTimeMachines([...timeMachines, newTimeMachine]);
      setNewTimeMachineName('');
      setNewTimeMachineDesc('');
      setShowNewTimeMachineForm(false);
    } catch (error) {
      console.error('Error creating time machine:', error);
      alert('创建时光机失败: ' + error.message);
    }
  };

  // 删除时光机
  const handleDeleteTimeMachine = async (id) => {
    try {
      const timeMachineRef = doc(db, 'timeMachines', id);
      await deleteDoc(timeMachineRef);
      setTimeMachines(timeMachines.filter(machine => machine.id !== id));
    } catch (error) {
      console.error('Error deleting time machine:', error);
      alert('删除时光机失败: ' + error.message);
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <div className="min-h-screen bg-cyber-dark text-white pt-24 px-4 sm:px-6 lg:px-8">
      {user && (
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="max-w-7xl mx-auto"
        >
          {/* Header Section */}
          <motion.div
            variants={itemVariants}
            className="bg-gradient-to-r from-cyber-purple/20 to-cyber-blue/20 rounded-lg p-6 mb-8 backdrop-blur-lg border border-cyber-purple/30"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-4">
                <AccountCircleIcon sx={{ fontSize: 48, color: '#00f6ff' }} />
                <div>
                  <h1 className="text-2xl font-bold text-cyber-blue">
                    欢迎回来, {user.email}
                  </h1>
                  <p className="text-gray-400">让我们开始创建新的时光记忆</p>
                </div>
              </div>
              <div className="flex space-x-4">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="p-2 rounded-full bg-cyber-purple/20 hover:bg-cyber-purple/30 transition-colors"
                >
                  <NotificationsIcon />
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => setShowSettings(true)}
                  className="p-2 rounded-full bg-cyber-purple/20 hover:bg-cyber-purple/30 transition-colors"
                >
                  <SettingsIcon />
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={handleLogout}
                  className="p-2 rounded-full bg-red-500/20 hover:bg-red-500/30 transition-colors text-red-500"
                >
                  <LogoutIcon />
                </motion.button>
              </div>
            </div>
          </motion.div>

          {/* Settings Modal */}
          <AnimatePresence>
            {showSettings && (
              <UserSettings
                user={user}
                onClose={() => setShowSettings(false)}
              />
            )}
          </AnimatePresence>

          {/* API Keys Section */}
          <motion.div
            variants={itemVariants}
            className="bg-cyber-dark/50 rounded-lg p-6 mb-8 backdrop-blur-lg border border-cyber-purple/30"
          >
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold text-cyber-blue flex items-center">
                <KeyIcon className="mr-2" /> API Keys
              </h2>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setShowNewApiForm(true)}
                className="bg-cyber-purple text-white px-4 py-2 rounded-md flex items-center"
              >
                <AddIcon className="mr-1" /> 创建 API Key
              </motion.button>
            </div>

            {showNewApiForm && (
              <motion.form
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                className="mb-6 p-4 bg-cyber-purple/10 rounded-lg"
                onSubmit={async (e) => {
                  e.preventDefault();
                  await handleCreateApiKey();
                }}
              >
                <input
                  type="text"
                  value={newApiName}
                  onChange={(e) => setNewApiName(e.target.value)}
                  placeholder="API Key 名称"
                  className="w-full p-2 mb-4 bg-cyber-dark/50 border border-cyber-purple/30 rounded-md text-white"
                  required
                />
                <div className="flex justify-end space-x-2">
                  <button
                    type="button"
                    onClick={() => setShowNewApiForm(false)}
                    className="px-4 py-2 rounded-md bg-cyber-dark/50 text-gray-300"
                  >
                    取消
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 rounded-md bg-cyber-purple text-white"
                  >
                    创建
                  </button>
                </div>
              </motion.form>
            )}

            <div className="space-y-4">
              {apiKeys.map((apiKey) => (
                <motion.div
                  key={apiKey.id}
                  variants={itemVariants}
                  className="p-4 rounded-lg bg-cyber-purple/10 hover:bg-cyber-purple/20 transition-colors"
                >
                  <div className="flex justify-between items-center">
                    <div>
                      <h3 className="font-medium text-cyber-blue">{apiKey.name}</h3>
                      <p className="text-sm text-gray-400">创建于: {formatTimestamp(apiKey.created)}</p>
                      <p className="text-sm font-mono mt-1 text-gray-300">{apiKey.key}</p>
                    </div>
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => handleDeleteApiKey(apiKey.id)}
                      className="text-red-500 hover:text-red-400"
                    >
                      <DeleteIcon />
                    </motion.button>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>

          {/* Time Machines Section */}
          <motion.div
            variants={itemVariants}
            className="bg-cyber-dark/50 rounded-lg p-6 backdrop-blur-lg border border-cyber-purple/30"
          >
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold text-cyber-blue flex items-center">
                <HistoryIcon className="mr-2" /> 我的时光机
              </h2>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setShowNewTimeMachineForm(true)}
                className="bg-cyber-purple text-white px-4 py-2 rounded-md flex items-center"
              >
                <AddIcon className="mr-1" /> 创建时光机
              </motion.button>
            </div>

            {showNewTimeMachineForm && (
              <motion.form
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                className="mb-6 p-4 bg-cyber-purple/10 rounded-lg"
                onSubmit={async (e) => {
                  e.preventDefault();
                  await handleCreateTimeMachine();
                }}
              >
                <input
                  type="text"
                  value={newTimeMachineName}
                  onChange={(e) => setNewTimeMachineName(e.target.value)}
                  placeholder="时光机名称"
                  className="w-full p-2 mb-4 bg-cyber-dark/50 border border-cyber-purple/30 rounded-md text-white"
                  required
                />
                <textarea
                  value={newTimeMachineDesc}
                  onChange={(e) => setNewTimeMachineDesc(e.target.value)}
                  placeholder="描述"
                  className="w-full p-2 mb-4 bg-cyber-dark/50 border border-cyber-purple/30 rounded-md text-white"
                  rows="3"
                  required
                />
                <div className="flex justify-end space-x-2">
                  <button
                    type="button"
                    onClick={() => setShowNewTimeMachineForm(false)}
                    className="px-4 py-2 rounded-md bg-cyber-dark/50 text-gray-300"
                  >
                    取消
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 rounded-md bg-cyber-purple text-white"
                  >
                    创建
                  </button>
                </div>
              </motion.form>
            )}

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {timeMachines.map((tm) => (
                <motion.div
                  key={tm.id}
                  variants={itemVariants}
                  className="p-6 rounded-lg bg-gradient-to-r from-cyber-purple/20 to-cyber-blue/20 backdrop-blur-lg border border-cyber-purple/30"
                >
                  <div className="flex justify-between items-start">
                    <div>
                      <h3 className="text-lg font-semibold text-cyber-blue">{tm.name}</h3>
                      <p className="text-sm text-gray-400 mt-1">{tm.description}</p>
                      <div className="mt-4 flex items-center space-x-4">
                        <span className="text-sm text-gray-300">
                          <TimelineIcon className="mr-1 text-cyber-blue" style={{ fontSize: '1rem' }} />
                          {tm.events} 个事件
                        </span>
                        <span className="text-sm text-gray-300">
                          最后更新: {formatTimestamp(tm.lastUpdated)}
                        </span>
                      </div>
                    </div>
                    <div className="flex space-x-2">
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className="text-cyber-blue hover:text-cyber-purple"
                      >
                        <EditIcon />
                      </motion.button>
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={() => handleDeleteTimeMachine(tm.id)}
                        className="text-red-500 hover:text-red-400"
                      >
                        <DeleteIcon />
                      </motion.button>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </motion.div>
      )}
    </div>
  );
};

export default Dashboard;
