import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import {
  Menu as MenuIcon,
  Close as CloseIcon,
  Home as HomeIcon,
  Info as AboutIcon,
  Timeline as TimelineIcon,
  Api as ApiIcon,
  People as CommunityIcon,
  AttachMoney as PricingIcon,
  Dashboard as DashboardIcon,
  Receipt as OrderIcon,
  ManageAccounts as UserManagementIcon,
  Logout as LogoutIcon,
  Login as LoginIcon,
  Person as PersonIcon,
  Settings as SettingsIcon,
  Forum as ForumIcon,
  LocalOffer as LocalOfferIcon,
  SupervisorAccount as SupervisorAccountIcon
} from '@mui/icons-material';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const location = useLocation();
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setIsAdmin(user?.uid === 'cfwL3Wued9VjRe3FcNlTX2MRIkA2');
    });

    return () => unsubscribe();
  }, [auth]);

  const handleNavigation = (anchor) => {
    if (location.pathname !== '/') {
      window.location.href = `/#${anchor}`;
    } else {
      const element = document.getElementById(anchor);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <nav className="fixed w-full z-50 bg-cyber-dark/80 backdrop-blur-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <motion.div
            className="flex-shrink-0 cursor-pointer"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <Link to="/" className="text-cyber-blue font-bold text-xl animate-glow">
              TimeMachine
            </Link>
          </motion.div>
          
          <div className="hidden md:block">
            <div className="ml-10 flex items-baseline space-x-4">
              {user ? (
                <>
                  <Link to="/dashboard">
                    <motion.div
                      className="text-gray-300 hover:text-cyber-blue px-3 py-2 rounded-md text-sm font-medium flex items-center space-x-1"
                      whileHover={{ scale: 1.1, color: '#00f6ff' }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <PersonIcon className="w-5 h-5" />
                      <span>仪表盘</span>
                    </motion.div>
                  </Link>
                  <Link to="/community">
                    <motion.div
                      className="text-gray-300 hover:text-cyber-blue px-3 py-2 rounded-md text-sm font-medium flex items-center space-x-1"
                      whileHover={{ scale: 1.1, color: '#00f6ff' }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <ForumIcon className="w-5 h-5" />
                      <span>社区</span>
                    </motion.div>
                  </Link>
                  <Link to="/pricing">
                    <motion.div
                      className="text-gray-300 hover:text-cyber-blue px-3 py-2 rounded-md text-sm font-medium flex items-center space-x-1"
                      whileHover={{ scale: 1.1, color: '#00f6ff' }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <PricingIcon className="w-5 h-5" />
                      <span>定价</span>
                    </motion.div>
                  </Link>
                  {isAdmin ? (
                    <Link to="/orders">
                      <motion.div
                        className="text-gray-300 hover:text-cyber-blue px-3 py-2 rounded-md text-sm font-medium flex items-center space-x-1"
                        whileHover={{ scale: 1.1, color: '#00f6ff' }}
                        whileTap={{ scale: 0.95 }}
                      >
                        <PricingIcon className="w-5 h-5" />
                        <span>订单管理</span>
                      </motion.div>
                    </Link>
                  ) : (
                    <Link to="/orders">
                      <motion.div
                        className="text-gray-300 hover:text-cyber-blue px-3 py-2 rounded-md text-sm font-medium flex items-center space-x-1"
                        whileHover={{ scale: 1.1, color: '#00f6ff' }}
                        whileTap={{ scale: 0.95 }}
                      >
                        <PricingIcon className="w-5 h-5" />
                        <span>我的订单</span>
                      </motion.div>
                    </Link>
                  )}
                  {isAdmin && (
                    <Link to="/user-management">
                      <motion.div
                        className="text-gray-300 hover:text-cyber-blue px-3 py-2 rounded-md text-sm font-medium flex items-center space-x-1"
                        whileHover={{ scale: 1.1, color: '#00f6ff' }}
                        whileTap={{ scale: 0.95 }}
                      >
                        <UserManagementIcon className="w-5 h-5" />
                        <span>用户管理</span>
                      </motion.div>
                    </Link>
                  )}
                </>
              ) : (
                <>
                  {['Home', 'Features', 'Contact'].map((item) => (
                    <motion.div
                      key={item}
                      onClick={() => handleNavigation(item.toLowerCase())}
                      className="cursor-pointer text-gray-300 hover:text-cyber-blue px-3 py-2 rounded-md text-sm font-medium"
                      whileHover={{ scale: 1.1, color: '#00f6ff' }}
                      whileTap={{ scale: 0.95 }}
                    >
                      {item}
                    </motion.div>
                  ))}
                  <Link to="/api" className="text-gray-300 hover:text-cyber-blue px-3 py-2 rounded-md text-sm font-medium">
                    API
                  </Link>
                  <Link to="/pricing">
                    <motion.div
                      className="text-gray-300 hover:text-cyber-blue px-3 py-2 rounded-md text-sm font-medium"
                      whileHover={{ scale: 1.1, color: '#00f6ff' }}
                      whileTap={{ scale: 0.95 }}
                    >
                      Pricing
                    </motion.div>
                  </Link>
                  <motion.div
                    onClick={() => handleNavigation('about')}
                    className="cursor-pointer text-gray-300 hover:text-cyber-blue px-3 py-2 rounded-md text-sm font-medium"
                    whileHover={{ scale: 1.1, color: '#00f6ff' }}
                    whileTap={{ scale: 0.95 }}
                  >
                    About
                  </motion.div>
                </>
              )}
            </div>
          </div>

          <div className="hidden md:block">
            <div className="ml-4 flex items-center md:ml-6 space-x-4">
              {user ? (
                <div className="flex items-center space-x-4">
                  <motion.div
                    className="flex items-center space-x-2"
                    whileHover={{ scale: 1.05 }}
                  >
                    <img
                      src={user.photoURL || `https://ui-avatars.com/api/?name=${user.displayName || 'User'}`}
                      alt="Profile"
                      className="w-8 h-8 rounded-full border-2 border-cyber-purple"
                    />
                    <span className="text-gray-300">{user.displayName || 'User'}</span>
                  </motion.div>
                  <motion.button
                    onClick={handleLogout}
                    className="text-gray-300 hover:text-cyber-blue"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <LogoutIcon />
                  </motion.button>
                </div>
              ) : (
                <>
                  <Link to="/login">
                    <motion.button
                      className="bg-cyber-purple text-white px-4 py-2 rounded-md"
                      whileHover={{ scale: 1.05, backgroundColor: '#9333ea' }}
                      whileTap={{ scale: 0.95 }}
                    >
                      Login
                    </motion.button>
                  </Link>
                  <Link to="/signup">
                    <motion.button
                      className="bg-cyber-blue text-white px-4 py-2 rounded-md"
                      whileHover={{ scale: 1.05, backgroundColor: '#0284c7' }}
                      whileTap={{ scale: 0.95 }}
                    >
                      Signup
                    </motion.button>
                  </Link>
                </>
              )}
            </div>
          </div>

          {/* Mobile menu button */}
          <div className="-mr-2 flex md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            >
              <span className="sr-only">Open main menu</span>
              {!isOpen ? (
                <MenuIcon className="block h-6 w-6" />
              ) : (
                <CloseIcon className="block h-6 w-6" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div className={`${isOpen ? 'block' : 'hidden'} md:hidden`}>
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          {user ? (
            <>
              <Link to="/dashboard">
                <motion.div
                  className="text-gray-300 hover:text-cyber-blue block px-3 py-2 rounded-md text-base font-medium"
                  whileHover={{ scale: 1.05 }}
                >
                  仪表盘
                </motion.div>
              </Link>
              <Link to="/community">
                <motion.div
                  className="text-gray-300 hover:text-cyber-blue block px-3 py-2 rounded-md text-base font-medium"
                  whileHover={{ scale: 1.05 }}
                >
                  社区
                </motion.div>
              </Link>
              <Link to="/pricing">
                <motion.div
                  className="text-gray-300 hover:text-cyber-blue block px-3 py-2 rounded-md text-base font-medium flex items-center space-x-1"
                  whileHover={{ scale: 1.05 }}
                >
                  <PricingIcon className="w-5 h-5" />
                  <span>定价</span>
                </motion.div>
              </Link>
              {isAdmin ? (
                <Link to="/orders">
                  <motion.div
                    className="text-gray-300 hover:text-cyber-blue block px-3 py-2 rounded-md text-base font-medium flex items-center space-x-1"
                    whileHover={{ scale: 1.05 }}
                  >
                    <PricingIcon className="w-5 h-5" />
                    <span>订单管理</span>
                  </motion.div>
                </Link>
              ) : (
                <Link to="/orders">
                  <motion.div
                    className="text-gray-300 hover:text-cyber-blue block px-3 py-2 rounded-md text-base font-medium flex items-center space-x-1"
                    whileHover={{ scale: 1.05 }}
                  >
                    <PricingIcon className="w-5 h-5" />
                    <span>我的订单</span>
                  </motion.div>
                </Link>
              )}
              {isAdmin && (
                <Link to="/user-management">
                  <motion.div
                    className="text-gray-300 hover:text-cyber-blue block px-3 py-2 rounded-md text-base font-medium flex items-center space-x-2"
                    whileHover={{ scale: 1.05 }}
                  >
                    <UserManagementIcon className="w-5 h-5" />
                    <span>用户管理</span>
                  </motion.div>
                </Link>
              )}
            </>
          ) : (
            <>
              {['Home', 'Features', 'Contact'].map((item) => (
                <motion.div
                  key={item}
                  onClick={() => handleNavigation(item.toLowerCase())}
                  className="text-gray-300 hover:text-cyber-blue block px-3 py-2 rounded-md text-base font-medium"
                  whileHover={{ scale: 1.05 }}
                >
                  {item}
                </motion.div>
              ))}
              <Link to="/api">
                <motion.div
                  className="text-gray-300 hover:text-cyber-blue block px-3 py-2 rounded-md text-base font-medium"
                  whileHover={{ scale: 1.05 }}
                >
                  API
                </motion.div>
              </Link>
              <Link to="/pricing">
                <motion.div
                  className="text-gray-300 hover:text-cyber-blue block px-3 py-2 rounded-md text-base font-medium"
                  whileHover={{ scale: 1.05 }}
                >
                  Pricing
                </motion.div>
              </Link>
              <motion.div
                onClick={() => handleNavigation('about')}
                className="text-gray-300 hover:text-cyber-blue block px-3 py-2 rounded-md text-base font-medium"
                whileHover={{ scale: 1.05 }}
              >
                About
              </motion.div>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
