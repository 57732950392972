import React from 'react';
import { motion } from 'framer-motion';

const About = () => {
  const features = [
    {
      title: 'Web3 Integration',
      description: 'Seamlessly integrated with blockchain technology for secure and transparent time-travel transactions.',
      icon: '🌐'
    },
    {
      title: 'Quantum Computing',
      description: 'Powered by advanced quantum algorithms to ensure precise temporal calculations.',
      icon: '⚛️'
    },
    {
      title: 'Smart Contracts',
      description: 'Automated time-travel agreements secured by blockchain smart contracts.',
      icon: '📜'
    },
    {
      title: 'Time NFTs',
      description: 'Collect and trade unique moments in time as non-fungible tokens.',
      icon: '🎯'
    }
  ];

  return (
    <div className="min-h-screen bg-cyber-dark py-20 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold text-cyber-blue mb-4 animate-glow">
            About Time Machine
          </h2>
          <p className="text-gray-300 text-xl max-w-3xl mx-auto">
            Experience the convergence of blockchain technology and temporal mechanics
            in our revolutionary Web3 time machine platform.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={feature.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              viewport={{ once: true }}
              className="bg-cyber-dark/50 p-6 rounded-xl border border-cyber-purple/30 hover:border-cyber-blue/50 transition-colors"
            >
              <div className="text-4xl mb-4">{feature.icon}</div>
              <h3 className="text-xl font-semibold text-cyber-blue mb-2">
                {feature.title}
              </h3>
              <p className="text-gray-400">
                {feature.description}
              </p>
            </motion.div>
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="mt-20 text-center"
        >
          <h3 className="text-3xl font-bold text-cyber-blue mb-6">
            Ready to Start Your Time Journey?
          </h3>
          <motion.button
            className="bg-cyber-purple text-white px-8 py-3 rounded-lg text-lg font-semibold"
            whileHover={{ scale: 1.05, backgroundColor: '#9333ea' }}
            whileTap={{ scale: 0.95 }}
          >
            Launch App
          </motion.button>
        </motion.div>
      </div>
    </div>
  );
};

export default About;
