import React, { useState } from 'react';
import { motion } from 'framer-motion';
import {
  Code as CodeIcon,
  ContentCopy as ContentCopyIcon,
  CheckCircle as CheckCircleIcon,
  Api as ApiIcon,
} from '@mui/icons-material';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

const Api = () => {
  const [copied, setCopied] = useState(false);

  const baseUrl = 'https://timemachine.us.kg/api';
  const apiKey = 'YOUR_API_KEY';

  const endpoints = [
    {
      name: '创建时光机消息',
      method: 'POST',
      endpoint: '/timemachine',
      description: '创建新的时光机消息',
      example: {
        request: `curl -X POST ${baseUrl}/timemachine \\
  -H "Authorization: Bearer ${apiKey}" \\
  -H "Content-Type: application/json" \\
  -d '{
    "message": "你的时光机消息"
  }'`,
        response: `{
  "success": true,
  "data": {
    "id": "msg_123456789",
    "message": "你的时光机消息",
    "timestamp": "2024-12-09T14:00:00Z"
  }
}`
      }
    },
    {
      name: '获取时光机消息',
      method: 'GET',
      endpoint: '/timemachine',
      description: '获取你的时光机消息列表',
      example: {
        request: `curl ${baseUrl}/timemachine \\
  -H "Authorization: Bearer ${apiKey}"`,
        response: `{
  "success": true,
  "data": [
    {
      "id": "msg_123456789",
      "message": "你的时光机消息",
      "timestamp": "2024-12-09T14:00:00Z"
    },
    {
      "id": "msg_987654321",
      "message": "另一条时光机消息",
      "timestamp": "2024-12-09T13:00:00Z"
    }
  ]
}`
      }
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="min-h-screen bg-cyber-dark text-white pt-24 px-4 sm:px-6 lg:px-8">
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="max-w-7xl mx-auto"
      >
        {/* Header */}
        <motion.div
          variants={itemVariants}
          className="bg-gradient-to-r from-cyber-purple/20 to-cyber-blue/20 rounded-lg p-8 mb-8 backdrop-blur-lg border border-cyber-purple/30"
        >
          <div className="flex items-center space-x-4 mb-4">
            <ApiIcon sx={{ fontSize: 48, color: '#00f6ff' }} />
            <div>
              <h1 className="text-3xl font-bold text-cyber-blue">API 文档</h1>
              <p className="text-gray-400">使用我们的 API 构建自己的时光机应用</p>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6">
            <div className="bg-cyber-dark/50 p-4 rounded-lg border border-cyber-purple/30">
              <h3 className="text-lg font-semibold mb-2 text-cyber-blue">Base URL</h3>
              <code className="bg-black/50 p-2 rounded text-sm block">{baseUrl}</code>
            </div>
            <div className="bg-cyber-dark/50 p-4 rounded-lg border border-cyber-purple/30">
              <h3 className="text-lg font-semibold mb-2 text-cyber-blue">认证</h3>
              <code className="bg-black/50 p-2 rounded text-sm block">
                Authorization: Bearer YOUR_API_KEY
              </code>
              <p className="text-sm text-gray-400 mt-2">
                在个人仪表板中获取你的 API Key。每个 API Key 每天限制调用 100 次。
              </p>
            </div>
          </div>
        </motion.div>

        {/* Endpoints */}
        <div className="space-y-6">
          {endpoints.map((endpoint, index) => (
            <motion.div
              key={endpoint.name}
              variants={itemVariants}
              className="bg-cyber-dark/50 rounded-lg p-6 backdrop-blur-lg border border-cyber-purple/30"
            >
              <div className="flex items-center space-x-2 mb-4">
                <CodeIcon className="text-cyber-blue" />
                <h2 className="text-xl font-semibold text-cyber-blue">{endpoint.name}</h2>
              </div>
              <div className="mb-4">
                <span className="inline-block bg-cyber-purple/20 text-cyber-purple px-3 py-1 rounded-full text-sm mr-2">
                  {endpoint.method}
                </span>
                <code className="text-gray-300">{endpoint.endpoint}</code>
              </div>
              <p className="text-gray-400 mb-4">{endpoint.description}</p>
              
              <div className="space-y-4">
                <div>
                  <h3 className="text-lg font-semibold mb-2 text-cyber-blue">示例请求</h3>
                  <div className="relative">
                    <SyntaxHighlighter
                      language="bash"
                      style={atomDark}
                      className="rounded-lg"
                    >
                      {endpoint.example.request}
                    </SyntaxHighlighter>
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => handleCopy(endpoint.example.request)}
                      className="absolute top-2 right-2 p-2 rounded-full bg-cyber-purple/20 hover:bg-cyber-purple/30 transition-colors"
                    >
                      {copied ? <CheckCircleIcon /> : <ContentCopyIcon />}
                    </motion.button>
                  </div>
                </div>
                <div>
                  <h3 className="text-lg font-semibold mb-2 text-cyber-blue">示例响应</h3>
                  <SyntaxHighlighter
                    language="json"
                    style={atomDark}
                    className="rounded-lg"
                  >
                    {endpoint.example.response}
                  </SyntaxHighlighter>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default Api;
