import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { 
  getAuth, 
  createUserWithEmailAndPassword,
  updateProfile,
  deleteUser,
  signInWithEmailAndPassword
} from 'firebase/auth';
import { 
  getFirestore, 
  collection, 
  query, 
  getDocs, 
  doc, 
  updateDoc, 
  deleteDoc, 
  setDoc,
  where,
  orderBy 
} from 'firebase/firestore';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Tooltip,
  Chip,
  Alert,
  Snackbar,
  CircularProgress,
  Typography
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Add as AddIcon
} from '@mui/icons-material';

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [isAdmin, setIsAdmin] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMode, setDialogMode] = useState('add');
  const [selectedUser, setSelectedUser] = useState(null);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    displayName: '',
    role: 'user',
    status: 'active'
  });

  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user?.uid === 'cfwL3Wued9VjRe3FcNlTX2MRIkA2') {
        setIsAdmin(true);
        fetchUsers();
      } else {
        setIsAdmin(false);
        setError('只有管理员可以访问此页面');
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchUsers = async () => {
    try {
      const usersRef = collection(db, 'users');
      const q = query(usersRef);
      const snapshot = await getDocs(q);
      const usersList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setUsers(usersList);
      setError('');
    } catch (error) {
      console.error('获取用户列表失败：', error);
      setError('获取用户列表失败：' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDialog = (mode, user = null) => {
    setDialogMode(mode);
    if (user) {
      setSelectedUser(user);
      setFormData({
        email: user.email || '',
        password: '',
        displayName: user.displayName || '',
        role: user.role || 'user',
        status: user.status || 'active'
      });
    } else {
      setSelectedUser(null);
      setFormData({
        email: '',
        password: '',
        displayName: '',
        role: 'user',
        status: 'active'
      });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedUser(null);
    setFormData({
      email: '',
      password: '',
      displayName: '',
      role: 'user',
      status: 'active'
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    try {
      if (dialogMode === 'add') {
        // 创建新用户
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          formData.email,
          formData.password
        );

        // 更新用户信息
        await updateProfile(userCredential.user, {
          displayName: formData.displayName
        });

        // 在 Firestore 中创建用户文档
        await setDoc(doc(db, 'users', userCredential.user.uid), {
          email: formData.email,
          displayName: formData.displayName,
          role: formData.role,
          status: formData.status,
          createdAt: new Date(),
          updatedAt: new Date()
        });

        // 如果创建的不是管理员，重新登录为管理员
        if (formData.role !== 'admin') {
          const adminEmail = auth.currentUser.email;
          const adminPassword = ''; // 这里需要管理员重新登录
          await signInWithEmailAndPassword(auth, adminEmail, adminPassword);
        }
      } else {
        // 更新用户
        const userRef = doc(db, 'users', selectedUser.id);
        await updateDoc(userRef, {
          displayName: formData.displayName,
          role: formData.role,
          status: formData.status,
          updatedAt: new Date()
        });
      }

      setSnackbar({
        open: true,
        message: `用户${dialogMode === 'add' ? '添加' : '更新'}成功`,
        severity: 'success'
      });
      handleCloseDialog();
      fetchUsers();
    } catch (error) {
      console.error('操作失败：', error);
      setSnackbar({
        open: true,
        message: '操作失败：' + error.message,
        severity: 'error'
      });
    }
  };

  const handleDeleteUser = async (userId) => {
    if (window.confirm('确定要删除这个用户吗？此操作不可撤销。')) {
      try {
        await deleteDoc(doc(db, 'users', userId));
        setSnackbar({
          open: true,
          message: '用户删除成功',
          severity: 'success'
        });
        fetchUsers();
      } catch (error) {
        console.error('删除用户失败：', error);
        setSnackbar({
          open: true,
          message: '删除用户失败：' + error.message,
          severity: 'error'
        });
      }
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-black text-white flex items-center justify-center">
        <CircularProgress />
      </div>
    );
  }

  if (!isAdmin) {
    return (
      <div className="min-h-screen bg-black text-white flex items-center justify-center">
        <Typography variant="h6">只有管理员可以访问此页面</Typography>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black text-white p-8 pt-24">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-7xl mx-auto"
      >
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold">用户管理</h1>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => handleOpenDialog('add')}
            className="bg-cyber-blue hover:bg-cyber-blue-dark"
          >
            添加用户
          </Button>
        </div>

        {error && (
          <Alert severity="error" className="mb-4">
            {error}
          </Alert>
        )}

        <TableContainer component={Paper} className="bg-gray-900">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="text-gray-300">邮箱</TableCell>
                <TableCell className="text-gray-300">显示名称</TableCell>
                <TableCell className="text-gray-300">角色</TableCell>
                <TableCell className="text-gray-300">状态</TableCell>
                <TableCell className="text-gray-300">操作</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell className="text-gray-300">{user.email}</TableCell>
                  <TableCell className="text-gray-300">{user.displayName}</TableCell>
                  <TableCell>
                    <Chip
                      label={user.role === 'admin' ? '管理员' : '用户'}
                      color={user.role === 'admin' ? 'secondary' : 'default'}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={user.status === 'active' ? '正常' : '禁用'}
                      color={user.status === 'active' ? 'success' : 'error'}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <Tooltip title="编辑用户">
                      <IconButton
                        onClick={() => handleOpenDialog('edit', user)}
                        className="text-cyber-blue hover:text-cyber-blue-light"
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="删除用户">
                      <IconButton
                        onClick={() => handleDeleteUser(user.id)}
                        className="text-red-400 hover:text-red-300"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* 添加/编辑用户对话框 */}
        <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
          <DialogTitle>{dialogMode === 'add' ? '添加用户' : '编辑用户'}</DialogTitle>
          <DialogContent>
            <div className="space-y-4 pt-4">
              <TextField
                fullWidth
                label="邮箱"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                disabled={dialogMode === 'edit'}
              />
              {dialogMode === 'add' && (
                <TextField
                  fullWidth
                  label="密码"
                  name="password"
                  type="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                />
              )}
              <TextField
                fullWidth
                label="显示名称"
                name="displayName"
                value={formData.displayName}
                onChange={handleInputChange}
              />
              <TextField
                fullWidth
                select
                label="角色"
                name="role"
                value={formData.role}
                onChange={handleInputChange}
                SelectProps={{
                  native: true,
                }}
              >
                <option value="user">用户</option>
                <option value="admin">管理员</option>
              </TextField>
              <TextField
                fullWidth
                select
                label="状态"
                name="status"
                value={formData.status}
                onChange={handleInputChange}
                SelectProps={{
                  native: true,
                }}
              >
                <option value="active">正常</option>
                <option value="disabled">禁用</option>
              </TextField>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>取消</Button>
            <Button onClick={handleSubmit} variant="contained" color="primary">
              {dialogMode === 'add' ? '添加' : '保存'}
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert 
            onClose={handleCloseSnackbar} 
            severity={snackbar.severity}
            variant="filled"
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </motion.div>
    </div>
  );
};

export default UserManagement;
