import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs, deleteDoc, doc, orderBy } from 'firebase/firestore';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Chip,
  Alert,
  Snackbar,
  CircularProgress,
  Typography,
  Link
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';

const OrderHistory = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [needsIndex, setNeedsIndex] = useState(false);
  const auth = getAuth();
  const db = getFirestore();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAdmin(user?.uid === 'cfwL3Wued9VjRe3FcNlTX2MRIkA2');
      if (user) {
        fetchOrders(user);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchOrders = async (user) => {
    try {
      const ordersRef = collection(db, 'orders');
      let ordersQuery;

      if (user.uid === 'cfwL3Wued9VjRe3FcNlTX2MRIkA2') {
        // 管理员可以看到所有订单
        ordersQuery = query(ordersRef, orderBy('createdAt', 'desc'));
      } else {
        // 普通用户只能看到自己的订单
        try {
          // 首先尝试使用复合查询
          ordersQuery = query(
            ordersRef,
            where('userId', '==', user.uid),
            orderBy('createdAt', 'desc')
          );
          const snapshot = await getDocs(ordersQuery);
          const ordersList = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            createdAt: doc.data().createdAt?.toDate()
          }));
          setOrders(ordersList);
          setError('');
          setNeedsIndex(false);
          return;
        } catch (indexError) {
          if (indexError.code === 'failed-precondition') {
            // 如果索引不存在，使用简单查询
            console.log('需要创建索引，使用简单查询');
            ordersQuery = query(
              ordersRef,
              where('userId', '==', user.uid)
            );
            setNeedsIndex(true);
          } else {
            throw indexError;
          }
        }
      }

      const snapshot = await getDocs(ordersQuery);
      let ordersList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate()
      }));

      // 如果没有索引，手动排序
      if (needsIndex) {
        ordersList.sort((a, b) => {
          if (!a.createdAt || !b.createdAt) return 0;
          return b.createdAt - a.createdAt;
        });
      }

      setOrders(ordersList);
      setError('');
    } catch (error) {
      console.error('获取订单历史失败：', error);
      if (isAdmin) {
        setError('获取订单历史失败：' + error.message);
      }
      if (error.code === 'failed-precondition') {
        setNeedsIndex(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteOrder = async (orderId) => {
    if (!isAdmin) return;
    
    if (window.confirm('确定要删除这个订单吗？')) {
      try {
        const orderRef = doc(db, 'orders', orderId);
        await deleteDoc(orderRef);
        setSnackbar({
          open: true,
          message: '订单删除成功',
          severity: 'success'
        });
        // 重新获取订单列表
        fetchOrders(auth.currentUser);
      } catch (error) {
        console.error('删除订单失败：', error);
        setSnackbar({
          open: true,
          message: '删除订单失败：' + error.message,
          severity: 'error'
        });
      }
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const getStatusChip = (status) => {
    const statusConfig = {
      pending: { label: '待支付', color: 'warning' },
      completed: { label: '已完成', color: 'success' },
      failed: { label: '失败', color: 'error' }
    };

    const config = statusConfig[status] || { label: status, color: 'default' };

    return (
      <Chip
        label={config.label}
        color={config.color}
        size="small"
      />
    );
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-black text-white flex items-center justify-center">
        <CircularProgress />
      </div>
    );
  }

  if (!auth.currentUser) {
    return (
      <div className="min-h-screen bg-black text-white flex items-center justify-center">
        <Typography variant="h6">请先登录查看订单</Typography>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black text-white p-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-7xl mx-auto"
      >
        <h1 className="text-3xl font-bold mb-8">
          {isAdmin ? '订单管理' : '我的订单'}
        </h1>

        {needsIndex && !isAdmin && (
          <Alert severity="info" className="mb-4">
            系统正在优化数据索引，暂时可能无法按时间排序显示订单。
          </Alert>
        )}

        {error && isAdmin && (
          <div className="bg-red-600 text-white p-4 rounded-lg mb-4">
            {error}
          </div>
        )}

        {orders.length === 0 ? (
          <div className="text-center py-8">
            <Typography variant="h6" className="text-gray-400">
              暂无订单记录
            </Typography>
          </div>
        ) : (
          <TableContainer component={Paper} className="bg-gray-900">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="text-gray-300">订单ID</TableCell>
                  <TableCell className="text-gray-300">计划</TableCell>
                  <TableCell className="text-gray-300">金额 (USDT)</TableCell>
                  <TableCell className="text-gray-300">支付地址</TableCell>
                  <TableCell className="text-gray-300">状态</TableCell>
                  <TableCell className="text-gray-300">创建时间</TableCell>
                  {isAdmin && <TableCell className="text-gray-300">用户ID</TableCell>}
                  {isAdmin && <TableCell className="text-gray-300">操作</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((order) => (
                  <TableRow key={order.id}>
                    <TableCell className="text-gray-300">{order.id}</TableCell>
                    <TableCell className="text-gray-300">{order.plan}</TableCell>
                    <TableCell className="text-gray-300">{order.amount}</TableCell>
                    <TableCell className="text-gray-300 font-mono">
                      {order.paymentAddress}
                    </TableCell>
                    <TableCell>
                      {getStatusChip(order.status)}
                    </TableCell>
                    <TableCell className="text-gray-300">
                      {order.createdAt?.toLocaleString()}
                    </TableCell>
                    {isAdmin && (
                      <TableCell className="text-gray-300">{order.userId}</TableCell>
                    )}
                    {isAdmin && (
                      <TableCell>
                        <Tooltip title="删除订单">
                          <IconButton
                            onClick={() => handleDeleteOrder(order.id)}
                            className="text-red-400 hover:text-red-300"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert 
            onClose={handleCloseSnackbar} 
            severity={snackbar.severity}
            variant="filled"
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </motion.div>
    </div>
  );
};

export default OrderHistory;
