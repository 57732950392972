import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { motion } from 'framer-motion';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import About from './components/About';
import Contact from './components/Contact';
import CursorEffect from './components/CursorEffect';
import Timeline from './components/Timeline';
import Login from './components/Login';
import Signup from './components/Signup';
import Dashboard from './components/Dashboard';
import ForgotPassword from './components/ForgotPassword';
import Api from './components/Api';
import Community from './components/Community';
import Pricing from './components/Pricing';
import Payment from './components/Payment';
import OrderHistory from './components/OrderHistory';
import UserManagement from './components/UserManagement';
import PrivateRoute from './components/PrivateRoute';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <Router>
      <div className="bg-cyber-dark min-h-screen text-white">
        <CursorEffect />
        <Navbar />
        <ScrollToTop />
        
        <Routes>
          <Route path="/" element={
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
            >
              <div id="home">
                <Hero />
              </div>
              <div id="timeline">
                <Timeline />
              </div>
              <div id="about">
                <About />
              </div>
              <div id="contact">
                <Contact />
              </div>
            </motion.div>
          } />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/payment" element={
            <PrivateRoute>
              <Payment />
            </PrivateRoute>
          } />
          <Route path="/orders" element={
            <PrivateRoute>
              <OrderHistory />
            </PrivateRoute>
          } />
          <Route path="/user-management" element={
            <PrivateRoute>
              <UserManagement />
            </PrivateRoute>
          } />
          <Route path="/dashboard" element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          } />
          <Route path="/community" element={
            <PrivateRoute>
              <Community />
            </PrivateRoute>
          } />
          <Route path="/api" element={<Api />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
