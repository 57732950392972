import React from 'react';
import { motion } from 'framer-motion';
import { CheckCircle } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';

const PricingCard = ({ title, price, features, recommended, onSelect }) => (
  <motion.div
    whileHover={{ scale: 1.02 }}
    className={`relative p-6 rounded-xl ${
      recommended 
        ? 'bg-gradient-to-br from-purple-600 to-blue-500 text-white' 
        : 'bg-gray-900 border border-gray-800'
    } flex flex-col space-y-6`}
  >
    {recommended && (
      <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
        <span className="bg-purple-500 text-white px-4 py-1 rounded-full text-sm">
          推荐
        </span>
      </div>
    )}
    <div className="text-center">
      <h3 className="text-xl font-bold mb-2">{title}</h3>
      <div className="text-3xl font-bold mb-2">
        ${price}<span className="text-sm font-normal">/月</span>
      </div>
    </div>
    <ul className="flex-grow space-y-4">
      {features.map((feature, index) => (
        <li key={index} className="flex items-center space-x-2">
          <CheckCircle className="text-green-400" />
          <span>{feature}</span>
        </li>
      ))}
    </ul>
    <button
      onClick={() => onSelect(title, price)}
      className={`w-full py-3 rounded-lg font-semibold transition-all ${
        recommended
          ? 'bg-white text-purple-600 hover:bg-gray-100'
          : 'bg-purple-600 text-white hover:bg-purple-700'
      }`}
    >
      开始使用
    </button>
  </motion.div>
);

const Pricing = () => {
  const navigate = useNavigate();
  const auth = getAuth();

  const handleSelectPlan = (plan, price) => {
    if (!auth.currentUser) {
      // 如果用户未登录，重定向到登录页面
      navigate('/login', { state: { from: '/pricing' } });
      return;
    }

    // 导航到支付页面
    navigate('/payment', { 
      state: { 
        plan,
        price: plan === '基础版' ? 0 : price
      } 
    });
  };

  const plans = [
    {
      title: '基础版',
      price: '0',
      features: [
        '每日100次API调用',
        '基础时光机功能',
        '社区访问权限',
        '标准支持'
      ]
    },
    {
      title: '专业版',
      price: '6',
      recommended: true,
      features: [
        '每日1000次API调用',
        '高级时光机功能',
        'API密钥管理',
        '优先支持',
        '自定义主题'
      ]
    },
    {
      title: '企业版',
      price: '18',
      features: [
        '无限API调用',
        '专属服务器资源',
        '高级数据分析',
        '24/7专人支持',
        '自定义功能开发',
        '团队协作工具',
        'SLA保障',
        '产品提前体验'
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-black text-white py-20 px-4">
      <div className="max-w-6xl mx-auto">
        <div className="text-center mb-16">
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-4xl font-bold mb-4"
          >
            选择适合您的计划
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="text-gray-400"
          >
            无论您是个人用户还是企业用户，我们都有适合您的解决方案
          </motion.p>
        </div>
        <div className="grid md:grid-cols-3 gap-8">
          {plans.map((plan, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.2 }}
            >
              <PricingCard 
                {...plan} 
                onSelect={() => handleSelectPlan(plan.title, plan.price)}
              />
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
