import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';

// Import timeline images
import web3Genesis from '../assets/images/web3-genesis.jpg';
import nftExplosion from '../assets/images/nft-explosion.jpg';
import defiEvolution from '../assets/images/defi-evolution.jpg';
import metaverse from '../assets/images/metaverse.jpg';
import aiBlockchain from '../assets/images/ai-blockchain.jpg';

const timelineEvents = [
  {
    year: '2020',
    title: 'Web3 Genesis',
    description: '去中心化网络革命的开始，区块链技术开启新纪元',
    image: web3Genesis
  },
  {
    year: '2021',
    title: 'NFT Explosion',
    description: '数字艺术和收藏品改变市场格局，开创价值新维度',
    image: nftExplosion
  },
  {
    year: '2022',
    title: 'DeFi Evolution',
    description: '去中心化金融达到新高度，重构传统金融体系',
    image: defiEvolution
  },
  {
    year: '2023',
    title: 'Metaverse Integration',
    description: '虚拟世界互联互通，打造沉浸式Web3体验',
    image: metaverse
  },
  {
    year: '2024',
    title: 'AI Convergence',
    description: '人工智能与区块链融合，智能合约迈向自主决策',
    image: aiBlockchain
  }
];

const Timeline = () => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const containerRef = useRef(null);
  const [showWelcome, setShowWelcome] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current) return;
      
      const container = containerRef.current;
      const containerTop = container.offsetTop;
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;
      
      // 计算滚动进度
      const scrollProgress = scrollY - containerTop;
      
      // 处理首页到第一个时间点的过渡
      if (scrollProgress < windowHeight * 0.5) {
        setShowWelcome(true);
        setActiveIndex(-1);
      } else if (scrollProgress < windowHeight * 2) {
        setShowWelcome(false);
        setActiveIndex(0);
      } else {
        setShowWelcome(false);
        // 计算后续时间点
        const sectionHeight = windowHeight * 2;
        const adjustedProgress = scrollProgress - windowHeight;
        const newIndex = Math.floor(adjustedProgress / sectionHeight);
        
        // 处理最后一个时间点的淡出
        const lastSectionStart = (timelineEvents.length - 1) * sectionHeight;
        if (adjustedProgress >= lastSectionStart) {
          const lastSectionProgress = (adjustedProgress - lastSectionStart) / sectionHeight;
          if (lastSectionProgress > 0.3) { // 提前开始淡出
            setActiveIndex(-1);
          } else {
            setActiveIndex(timelineEvents.length - 1);
          }
        } else if (newIndex >= 0 && newIndex < timelineEvents.length) {
          setActiveIndex(newIndex);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div 
      ref={containerRef} 
      className="relative bg-cyber-dark"
      style={{ height: `${(timelineEvents.length * 2 + 0.6) * 100}vh` }} // 减少末尾的额外空间
    >
      <div className="fixed top-0 left-0 w-full h-screen flex items-center justify-center">
        {/* 欢迎页面 */}
        <motion.div
          className="absolute w-full h-full flex items-center justify-center"
          initial={{ opacity: 1 }}
          animate={{ 
            opacity: showWelcome ? 1 : 0,
            scale: showWelcome ? 1 : 0.8
          }}
          transition={{ duration: 0.8 }}
        >
          <div className="text-center space-y-6">
            <motion.h2 
              className="text-6xl font-bold text-cyber-blue"
              animate={{
                textShadow: ['0 0 20px #00f6ff', '0 0 40px #00f6ff', '0 0 20px #00f6ff'],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: 'easeInOut'
              }}
            >
              {/*Web3 时间机器*/}
            </motion.h2>
            <p className="text-xl text-gray-300">
              {/*Time machine — back to the future*/}
            </p>
          </div>
        </motion.div>

        {/* 时间点内容 */}
        {!showWelcome && timelineEvents.map((event, index) => (
          <motion.div
            key={event.year}
            className="absolute w-full h-full flex items-center justify-center px-4"
            initial={{ opacity: 0 }}
            animate={{ 
              opacity: activeIndex === index ? 1 : 0,
              scale: activeIndex === index ? 1 : 0.8,
              zIndex: activeIndex === index ? 1 : 0
            }}
            transition={{ 
              duration: 0.8,
              ease: "easeInOut"
            }}
          >
            <div className="max-w-6xl w-full grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
              <motion.div
                className="space-y-6 order-2 md:order-1"
                initial={{ x: -100, opacity: 0 }}
                animate={activeIndex === index ? { x: 0, opacity: 1 } : { x: -100, opacity: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
              >
                <motion.h2 
                  className="text-8xl font-bold text-cyber-blue"
                  animate={activeIndex === index ? {
                    textShadow: ['0 0 20px #00f6ff', '0 0 40px #00f6ff', '0 0 20px #00f6ff'],
                  } : {}}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    ease: 'easeInOut'
                  }}
                >
                  {event.year}
                </motion.h2>
                <h3 className="text-4xl font-semibold text-white bg-gradient-to-r from-cyber-blue to-cyber-purple bg-clip-text text-transparent">
                  {event.title}
                </h3>
                <p className="text-xl text-gray-300 leading-relaxed">
                  {event.description}
                </p>
                <motion.button
                  className="px-8 py-3 bg-cyber-blue/20 border border-cyber-blue rounded-lg text-cyber-blue font-semibold
                           hover:bg-cyber-blue hover:text-cyber-dark transition-all duration-300"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  了解更多
                </motion.button>
              </motion.div>
              
              <motion.div
                className="relative h-[500px] order-1 md:order-2"
                initial={{ x: 100, opacity: 0 }}
                animate={activeIndex === index ? { x: 0, opacity: 1 } : { x: 100, opacity: 0 }}
                transition={{ duration: 0.8, delay: 0.4 }}
              >
                <motion.div
                  className="absolute inset-0 rounded-2xl overflow-hidden"
                  whileHover={{ scale: 1.02 }}
                  transition={{ duration: 0.3 }}
                >
                  <img
                    src={event.image}
                    alt={event.title}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-cyber-dark via-transparent to-transparent" />
                  <motion.div
                    className="absolute inset-0 bg-cyber-blue/20"
                    animate={{
                      opacity: [0.1, 0.2, 0.1],
                    }}
                    transition={{
                      duration: 4,
                      repeat: Infinity,
                      ease: 'easeInOut'
                    }}
                  />
                </motion.div>
              </motion.div>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Timeline;
