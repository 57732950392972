import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, GithubAuthProvider } from 'firebase/auth';
import { auth, googleProvider, githubProvider } from '../firebase';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import GitHubIcon from '@mui/icons-material/GitHub';
import GoogleIcon from '@mui/icons-material/Google';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const navigate = useNavigate();

  const handleSignup = (e) => {
    e.preventDefault();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        console.log('Signed up:', user);
        setSnackbarMessage('注册成功');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setTimeout(() => navigate('/dashboard'), 2000);
      })
      .catch((error) => {
        const errorMessage = error.message;
        console.error('Error:', errorMessage);
        setSnackbarMessage('注册失败: ' + errorMessage);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  const handleGoogleSignup = () => {
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        console.log('Google signup success:', result.user);
        setSnackbarMessage('Google 注册成功');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setTimeout(() => navigate('/dashboard'), 2000);
      })
      .catch((error) => {
        console.error('Google signup error:', error);
        setSnackbarMessage('Google 注册失败: ' + error.message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  const handleGithubSignup = () => {
    signInWithPopup(auth, githubProvider)
      .then((result) => {
        console.log('GitHub signup success:', result.user);
        setSnackbarMessage('GitHub 注册成功');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setTimeout(() => navigate('/dashboard'), 2000);
      })
      .catch((error) => {
        console.error('GitHub signup error:', error);
        setSnackbarMessage('GitHub 注册失败: ' + error.message);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-cyber-dark text-white">
      <div className="w-full max-w-md space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-cyber-blue">
            创建新账户
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSignup}>
          <div className="rounded-md shadow-sm">
            <div className="mb-4">
              <label htmlFor="email" className="sr-only">
                电子邮件地址
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="appearance-none rounded-lg relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-cyber-blue focus:border-cyber-blue focus:z-10 sm:text-sm"
                placeholder="电子邮件地址"
                style={{
                  backgroundColor: '#0a0a0a',
                  color: '#00f6ff',
                  borderColor: '#7e22ce',
                  boxShadow: '0 0 10px #7e22ce',
                }}
              />
            </div>
            <div className="mb-4 relative">
              <label htmlFor="password" className="sr-only">
                密码
              </label>
              <input
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                autoComplete="new-password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="appearance-none rounded-lg relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-cyber-blue focus:border-cyber-blue focus:z-10 sm:text-sm"
                placeholder="密码"
                style={{
                  backgroundColor: '#0a0a0a',
                  color: '#00f6ff',
                  borderColor: '#7e22ce',
                  boxShadow: '0 0 10px #7e22ce',
                }}
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                style={{ color: '#7e22ce' }}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </button>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-cyber-purple hover:bg-cyber-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyber-blue transition-colors duration-300"
            >
              注册
            </button>
          </div>

          <div className="relative my-4">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-600"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-cyber-dark text-gray-300">或使用以下方式注册</span>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <motion.button
              type="button"
              onClick={handleGoogleSignup}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="flex items-center justify-center px-4 py-2 border border-cyber-purple rounded-lg hover:bg-cyber-purple/20 transition-colors duration-300"
            >
              <GoogleIcon className="mr-2" />
              Google
            </motion.button>
            <motion.button
              type="button"
              onClick={handleGithubSignup}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="flex items-center justify-center px-4 py-2 border border-cyber-purple rounded-lg hover:bg-cyber-purple/20 transition-colors duration-300"
            >
              <GitHubIcon className="mr-2" />
              GitHub
            </motion.button>
          </div>

          <div className="text-sm text-center">
            已有账号？ <Link to="/login" className="font-medium text-cyber-blue hover:text-cyber-purple">点击登录</Link>
          </div>
        </form>
      </div>
      <Snackbar open={snackbarOpen} autoHideDuration={2000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Signup;
