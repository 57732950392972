import React, { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import emailjs from '@emailjs/browser';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Footer from './Footer'; // Import the Footer component

const Contact = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
    from_name: '',
    from_email: '',
    message: ''
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    // 准备发送的数据
    const templateParams = {
      from_name: formData.from_name,
      from_email: formData.from_email,
      message: formData.message,
      reply_to: formData.from_email, // 确保可以回复到发送者的邮箱
    };

    emailjs.send(
      'service_g7d7gui',
      'template_r7xexzv',
      templateParams,
      'czkuhOtX6Yfx4uO79'
    )
      .then((result) => {
        console.log('Email sent successfully:', result.text);
        setSnackbar({
          open: true,
          message: '消息发送成功！',
          severity: 'success'
        });
        // Clear form
        setFormData({
          from_name: '',
          from_email: '',
          message: ''
        });
      })
      .catch((error) => {
        console.error('Email send failed:', error.text);
        setSnackbar({
          open: true,
          message: '发送失败，请稍后重试。',
          severity: 'error'
        });
      });
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <div id="contact" className="relative min-h-screen bg-cyber-dark py-20 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto relative z-10">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold text-cyber-blue mb-4 animate-glow">
            Contact Us
          </h2>
          <p className="text-gray-300 text-xl max-w-3xl mx-auto">
            Have questions about time travel? Want to learn more about our Web3 integration?
            We're here to help!
          </p>
        </motion.div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className="space-y-6"
          >
            <div>
              <h3 className="text-2xl font-semibold text-cyber-blue mb-4">
                Get in Touch
              </h3>
              <p className="text-gray-300">
                Join us on our mission to revolutionize time travel through blockchain technology.
                Our team is ready to assist you with any inquiries.
              </p>
            </div>

            <div className="space-y-4">
              <div className="flex items-center space-x-4">
                <div className="text-cyber-blue text-2xl">📍</div>
                <div className="text-gray-300">
                  Quantum Plaza, Silicon Valley, CA
                </div>
              </div>
              <div className="flex items-center space-x-4">
                <div className="text-cyber-blue text-2xl">📧</div>
                <div className="text-gray-300">
                  a@njopt.tech
                </div>
              </div>
              <div className="flex items-center space-x-4">
                <div className="text-cyber-blue text-2xl">☎️</div>
                <div className="text-gray-300">
                  +1 (888) TIME-WEB3
                </div>
              </div>
            </div>
          </motion.div>

          <motion.form
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            onSubmit={handleSubmit}
            className="space-y-6 relative z-10"
          >
            <div>
              <label htmlFor="from_name" className="block text-cyber-blue mb-2">
                Name
              </label>
              <input
                type="text"
                id="from_name"
                name="from_name"
                value={formData.from_name}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 rounded-lg bg-cyber-dark border border-cyber-purple/30 focus:border-cyber-blue/50 focus:outline-none text-white relative z-10"
                placeholder="Your name"
                style={{ backgroundColor: '#0a0a0a' }}
              />
            </div>
            <div>
              <label htmlFor="from_email" className="block text-cyber-blue mb-2">
                Email
              </label>
              <input
                type="email"
                id="from_email"
                name="from_email"
                value={formData.from_email}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 rounded-lg bg-cyber-dark border border-cyber-purple/30 focus:border-cyber-blue/50 focus:outline-none text-white relative z-10"
                placeholder="your.email@example.com"
                style={{ backgroundColor: '#0a0a0a' }}
              />
            </div>
            <div>
              <label htmlFor="message" className="block text-cyber-blue mb-2">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                rows="4"
                className="w-full px-4 py-2 rounded-lg bg-cyber-dark border border-cyber-purple/30 focus:border-cyber-blue/50 focus:outline-none text-white relative z-10"
                placeholder="Your message..."
                style={{ backgroundColor: '#0a0a0a' }}
              />
            </div>
            <motion.button
              type="submit"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              className="w-full py-3 px-6 rounded-lg bg-cyber-purple hover:bg-cyber-blue transition-colors duration-300 text-white font-semibold relative z-10"
            >
              Send Message
            </motion.button>
          </motion.form>
        </div>
      </div>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Footer />
    </div>
  );
};

export default Contact;
