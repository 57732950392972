import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { QRCodeSVG } from 'qrcode.react';
import {
  Paper,
  Typography,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import { ContentCopy as CopyIcon } from '@mui/icons-material';

// TRON API endpoints
const TRON_API_URL = 'https://api.trongrid.io';
const USDT_CONTRACT = 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t'; // USDT-TRC20 contract address

const Payment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = getAuth();
  const db = getFirestore();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [paymentAddress, setPaymentAddress] = useState('');
  const [orderDetails, setOrderDetails] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState('pending');
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  useEffect(() => {
    let mounted = true;

    const initializePayment = async () => {
      if (!location.state?.plan || !auth.currentUser) {
        navigate('/pricing');
        return;
      }

      if (orderDetails) {
        return; // 如果已经有订单，不要重复创建
      }

      try {
        const paymentAddr = 'TUpGzgmfbA7EQHr7EsXCTJixzG6VCBdVgb';
        
        if (mounted) {
          setPaymentAddress(paymentAddr);

          // 创建订单记录
          const order = {
            userId: auth.currentUser.uid,
            plan: location.state.plan,
            amount: location.state.price,
            paymentAddress: paymentAddr,
            status: 'pending',
            createdAt: serverTimestamp()
          };

          const docRef = await addDoc(collection(db, 'orders'), order);
          const newOrder = { ...order, id: docRef.id };
          setOrderDetails(newOrder);
          setLoading(false);

          // 开始检查支付状态
          startPaymentCheck(paymentAddr, order.amount);
        }
      } catch (error) {
        if (mounted) {
          setError('初始化支付失败：' + error.message);
          setLoading(false);
        }
      }
    };

    initializePayment();

    return () => {
      mounted = false;
    };
  }, [location.state, auth.currentUser, navigate, orderDetails]);

  const startPaymentCheck = async (address, amount) => {
    // 这里应该实现轮询检查 TRON 网络上的支付状态
    // 使用 TRON API 检查指定地址的 USDT-TRC20 转账
    const checkPayment = async () => {
      try {
        const response = await fetch(`${TRON_API_URL}/v1/accounts/${address}/transactions/trc20`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json'
          }
        });

        if (response.ok) {
          const data = await response.json();
          // 检查是否有匹配金额的转账交易
          const payment = data.data.find(tx => 
            tx.token_info.address === USDT_CONTRACT &&
            parseInt(tx.value) === parseInt(amount * 1e6) // USDT has 6 decimals
          );

          if (payment) {
            setPaymentStatus('completed');
            setShowSuccessDialog(true);
            // 更新订单状态（这里需要实现）
            return true;
          }
        }
        return false;
      } catch (error) {
        console.error('检查支付状态失败：', error);
        return false;
      }
    };

    // 每30秒检查一次，持续10分钟
    const interval = setInterval(async () => {
      const isPaid = await checkPayment();
      if (isPaid) {
        clearInterval(interval);
      }
    }, 30000);

    // 10分钟后停止检查
    setTimeout(() => {
      clearInterval(interval);
      if (paymentStatus === 'pending') {
        setPaymentStatus('failed');
      }
    }, 600000);
  };

  const handleCopyAddress = () => {
    navigator.clipboard.writeText(paymentAddress);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-black text-white p-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-2xl mx-auto"
      >
        <Paper className="bg-gray-900 p-8 rounded-xl">
          <Typography variant="h4" className="text-center mb-6">
            支付详情
          </Typography>

          {error && (
            <div className="bg-red-600 text-white p-4 rounded-lg mb-4">
              {error}
            </div>
          )}

          <div className="text-center mb-8">
            <Typography variant="h6" className="mb-2">
              支付金额：{orderDetails?.amount} USDT
            </Typography>
            <Typography variant="subtitle1" className="mb-4">
              选择计划：{orderDetails?.plan}
            </Typography>
          </div>

          <div className="flex justify-center mb-8">
            <QRCodeSVG
              value={`tron:${paymentAddress}?amount=${orderDetails?.amount}&token=usdt`}
              size={200}
              level="H"
              includeMargin={true}
              className="bg-white p-2 rounded-lg"
            />
          </div>

          <div className="bg-gray-800 p-4 rounded-lg mb-6">
            <div className="flex items-center justify-between">
              <Typography variant="body1" className="font-mono break-all">
                {paymentAddress}
              </Typography>
              <Button
                startIcon={<CopyIcon />}
                onClick={handleCopyAddress}
                className="ml-2"
              >
                复制
              </Button>
            </div>
          </div>

          <Typography variant="body2" className="text-gray-400 text-center mb-4">
            请使用支持 USDT-TRC20 的钱包转账支付
          </Typography>

          <div className="text-center">
            <Typography variant="body2" className="text-yellow-400">
              * 支付完成后请等待系统确认，大约需要1-2分钟
            </Typography>
          </div>
        </Paper>

        {/* 支付成功对话框 */}
        <Dialog
          open={showSuccessDialog}
          onClose={() => navigate('/dashboard')}
          PaperProps={{
            className: "bg-gray-900 text-white"
          }}
        >
          <DialogTitle>支付成功</DialogTitle>
          <DialogContent>
            <Typography>
              您的支付已确认，订单已完成。
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => navigate('/dashboard')}
              className="text-cyber-blue"
            >
              返回仪表盘
            </Button>
          </DialogActions>
        </Dialog>
      </motion.div>
    </div>
  );
};

export default Payment;
