import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { getAuth } from 'firebase/auth';
import {
  collection,
  addDoc,
  query,
  orderBy,
  onSnapshot,
  deleteDoc,
  doc,
  serverTimestamp,
  getFirestore,
  where,
} from 'firebase/firestore';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Search as SearchIcon,
  AccessTime as TimeIcon,
  Person as PersonIcon,
  Clear as ClearIcon,
} from '@mui/icons-material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const Community = () => {
  const [posts, setPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [newPost, setNewPost] = useState('');
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchType, setSearchType] = useState('author'); // 'author' or 'date'
  const [dateRange, setDateRange] = useState({
    start: '',
    end: '',
  });

  const auth = getAuth();
  const db = getFirestore();
  const ADMIN_UID = 'cfwL3Wued9VjRe3FcNlTX2MRIkA2';

  useEffect(() => {
    const q = query(collection(db, 'timeMachines'), orderBy('createdAt', 'desc'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const postsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setPosts(postsData);
      setFilteredPosts(postsData);
    });

    return () => unsubscribe();
  }, [db]);

  useEffect(() => {
    filterPosts();
  }, [searchTerm, searchType, dateRange, posts]);

  const filterPosts = () => {
    let filtered = [...posts];

    if (searchType === 'author' && searchTerm) {
      filtered = filtered.filter(post => 
        post.authorName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else if (searchType === 'date' && (dateRange.start || dateRange.end)) {
      filtered = filtered.filter(post => {
        if (!post.createdAt) return false;
        const postDate = post.createdAt.toDate();
        const start = dateRange.start ? new Date(dateRange.start) : new Date(0);
        const end = dateRange.end ? new Date(dateRange.end) : new Date();
        end.setHours(23, 59, 59, 999); // 设置为当天的最后一毫秒
        return postDate >= start && postDate <= end;
      });
    }

    setFilteredPosts(filtered);
  };

  const clearSearch = () => {
    setSearchTerm('');
    setDateRange({ start: '', end: '' });
    setFilteredPosts(posts);
  };

  const handleCreatePost = async () => {
    if (!newPost.trim() || loading) return;

    try {
      setLoading(true);
      await addDoc(collection(db, 'timeMachines'), {
        content: newPost,
        createdAt: serverTimestamp(),
        authorId: auth.currentUser.uid,
        authorName: auth.currentUser.displayName || 'Anonymous',
        authorPhoto: auth.currentUser.photoURL || '',
      });

      setNewPost('');
      setIsModalOpen(false);
      setSnackbar({
        open: true,
        message: '时光机创建成功！',
        severity: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: '创建失败: ' + error.message,
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeletePost = async (postId) => {
    if (auth.currentUser?.uid !== ADMIN_UID) {
      setSnackbar({
        open: true,
        message: '只有管理员可以删除帖子',
        severity: 'error'
      });
      return;
    }

    try {
      await deleteDoc(doc(db, 'timeMachines', postId));
      setSnackbar({
        open: true,
        message: '删除成功',
        severity: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: '删除失败: ' + error.message,
        severity: 'error'
      });
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate();
    return new Intl.DateTimeFormat('zh-CN', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };

  return (
    <div className="min-h-screen bg-cyber-dark text-white p-4 md:p-8">
      {/* Header with Search Icon */}
      <div className="fixed top-20 right-8 z-40">
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={() => setIsSearchModalOpen(true)}
          className="bg-cyber-purple/80 p-3 rounded-full shadow-lg hover:bg-cyber-purple transition-colors"
        >
          <SearchIcon className="text-white" />
        </motion.button>
      </div>

      {/* Header */}
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-center mb-20 mt-32"
      >
        <h1 className="text-5xl font-bold text-cyber-blue mb-6 animate-glow">时光机社区</h1>
        <p className="text-xl text-gray-400 max-w-2xl mx-auto leading-relaxed">
          在这里分享你的时光故事
        </p>
      </motion.div>

      {/* Search Modal */}
      <AnimatePresence>
        {isSearchModalOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/50 flex items-start justify-center z-50 pt-32 px-4"
          >
            <motion.div
              initial={{ scale: 0.9, y: -20 }}
              animate={{ scale: 1, y: 0 }}
              exit={{ scale: 0.9, y: -20 }}
              className="bg-cyber-dark-light p-6 rounded-lg w-full max-w-2xl border border-cyber-purple/30"
            >
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-bold text-cyber-blue">搜索时光机</h2>
                <button
                  onClick={() => setIsSearchModalOpen(false)}
                  className="text-gray-400 hover:text-white"
                >
                  <ClearIcon />
                </button>
              </div>

              <div className="space-y-6">
                <div className="flex gap-4">
                  <button
                    onClick={() => setSearchType('author')}
                    className={`flex-1 px-4 py-2 rounded-md transition-colors ${
                      searchType === 'author'
                        ? 'bg-cyber-purple text-white'
                        : 'bg-cyber-dark text-gray-400'
                    }`}
                  >
                    按用户名搜索
                  </button>
                  <button
                    onClick={() => setSearchType('date')}
                    className={`flex-1 px-4 py-2 rounded-md transition-colors ${
                      searchType === 'date'
                        ? 'bg-cyber-purple text-white'
                        : 'bg-cyber-dark text-gray-400'
                    }`}
                  >
                    按时间搜索
                  </button>
                </div>

                {searchType === 'author' ? (
                  <div className="relative">
                    <input
                      type="text"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      placeholder="输入用户名搜索..."
                      className="w-full bg-cyber-dark border border-cyber-purple/30 rounded-lg px-4 py-3 text-white focus:outline-none focus:border-cyber-blue"
                    />
                    <SearchIcon className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  </div>
                ) : (
                  <div className="flex flex-col gap-4">
                    <div className="flex items-center gap-4">
                      <span className="text-gray-400 whitespace-nowrap">起始日期</span>
                      <input
                        type="date"
                        value={dateRange.start}
                        onChange={(e) => setDateRange(prev => ({ ...prev, start: e.target.value }))}
                        className="flex-1 bg-cyber-dark border border-cyber-purple/30 rounded-lg px-4 py-2 text-white focus:outline-none focus:border-cyber-blue"
                      />
                    </div>
                    <div className="flex items-center gap-4">
                      <span className="text-gray-400 whitespace-nowrap">结束日期</span>
                      <input
                        type="date"
                        value={dateRange.end}
                        onChange={(e) => setDateRange(prev => ({ ...prev, end: e.target.value }))}
                        className="flex-1 bg-cyber-dark border border-cyber-purple/30 rounded-lg px-4 py-2 text-white focus:outline-none focus:border-cyber-blue"
                      />
                    </div>
                  </div>
                )}

                <div className="flex justify-between items-center">
                  <span className="text-gray-400">
                    找到 {filteredPosts.length} 条结果
                  </span>
                  <div className="flex gap-4">
                    <button
                      onClick={clearSearch}
                      className="px-4 py-2 text-gray-400 hover:text-white transition-colors flex items-center gap-2"
                    >
                      <ClearIcon />
                      清除
                    </button>
                    <button
                      onClick={() => setIsSearchModalOpen(false)}
                      className="px-6 py-2 bg-cyber-purple text-white rounded-md hover:bg-cyber-purple/80 transition-colors"
                    >
                      完成
                    </button>
                  </div>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Create Post Button */}
      <motion.div
        className="fixed bottom-8 right-8 z-50"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        <button
          onClick={() => setIsModalOpen(true)}
          className="bg-cyber-purple text-white p-4 rounded-full shadow-lg hover:bg-cyber-purple/80 transition-colors"
        >
          <AddIcon />
        </button>
      </motion.div>

      {/* Posts Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredPosts.map((post) => (
          <motion.div
            key={post.id}
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            className="bg-cyber-dark-light p-6 rounded-lg border border-cyber-purple/30 relative group"
          >
            <div className="flex items-center mb-4 space-x-2">
              <div className="w-10 h-10 rounded-full bg-cyber-purple/20 flex items-center justify-center">
                {post.authorPhoto ? (
                  <img
                    src={post.authorPhoto}
                    alt={post.authorName}
                    className="w-full h-full rounded-full object-cover"
                  />
                ) : (
                  <PersonIcon />
                )}
              </div>
              <div>
                <h3 className="font-semibold text-cyber-blue">{post.authorName}</h3>
                <div className="flex items-center text-xs text-gray-400">
                  <TimeIcon className="w-4 h-4 mr-1" />
                  {formatDate(post.createdAt)}
                </div>
              </div>
            </div>

            <p className="text-gray-300 mb-4">{post.content}</p>

            {auth.currentUser?.uid === ADMIN_UID && (
              <motion.button
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="absolute top-4 right-4 text-red-500 hover:text-red-400"
                onClick={() => handleDeletePost(post.id)}
              >
                <DeleteIcon />
              </motion.button>
            )}
          </motion.div>
        ))}
      </div>

      {/* Create Post Modal */}
      <AnimatePresence>
        {isModalOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4"
          >
            <motion.div
              initial={{ scale: 0.9, y: 20 }}
              animate={{ scale: 1, y: 0 }}
              exit={{ scale: 0.9, y: 20 }}
              className="bg-cyber-dark-light p-6 rounded-lg w-full max-w-lg border border-cyber-purple/30"
            >
              <h2 className="text-2xl font-bold mb-4 text-cyber-blue">创建时光机</h2>
              <textarea
                value={newPost}
                onChange={(e) => setNewPost(e.target.value)}
                placeholder="分享你的时光故事..."
                className="w-full h-32 bg-cyber-dark border border-cyber-purple/30 rounded-lg p-4 text-white resize-none focus:outline-none focus:border-cyber-blue"
              />
              <div className="flex justify-end space-x-4 mt-4">
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="px-4 py-2 text-gray-400 hover:text-white transition-colors"
                >
                  取消
                </button>
                <button
                  onClick={handleCreatePost}
                  disabled={loading || !newPost.trim()}
                  className={`px-4 py-2 bg-cyber-purple text-white rounded-lg ${
                    loading || !newPost.trim()
                      ? 'opacity-50 cursor-not-allowed'
                      : 'hover:bg-cyber-purple/80'
                  } transition-colors`}
                >
                  {loading ? '创建中...' : '创建'}
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Community;
