import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';

const Hero = () => {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate('/login');
  };

  const handleLearnMore = () => {
    // Scroll to about section or navigate to learn more page
    const aboutSection = document.querySelector('#about');
    if (aboutSection) {
      aboutSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="min-h-screen bg-cyber-dark flex flex-col items-center justify-center text-white relative overflow-hidden">
      {/* Background animated elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="cyber-grid"></div>
        {[...Array(20)].map((_, i) => (
          <div
            key={i}
            className="floating-particle"
            style={{
              '--delay': `${Math.random() * 5}s`,
              '--duration': `${5 + Math.random() * 5}s`,
              '--x-start': `${Math.random() * 100}%`,
              '--x-end': `${Math.random() * 100}%`,
              '--y-start': `${Math.random() * 100}%`,
              '--y-end': `${Math.random() * 100}%`,
            }}
          ></div>
        ))}
      </div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="text-center z-10 px-4"
      >
        {/* Chinese Slogan with Magical Effect */}
        <div className="mb-6">
          <motion.div
            className="text-xl md:text-2xl font-bold mb-4 cyber-glitch-text"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
          >
            <TypeAnimation
              sequence={[
                '',
                500,
                '链',
                200,
                '链上',
                200,
                '链上不',
                200,
                '链上不改',
                200,
                '链上不改，',
                200,
                '链上不改，时',
                200,
                '链上不改，时光',
                200,
                '链上不改，时光重',
                200,
                '链上不改，时光重来',
                1000,
                '链上不改，时光重来 ⚡',
                1000,
              ]}
              wrapper="span"
              speed={50}
              repeat={Infinity}
              className="magical-text tracking-wider"
            />
          </motion.div>
        </div>

        <motion.h1 
          className="text-6xl md:text-8xl font-bold mb-6 text-cyber-blue animate-glow"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          Time Machine
        </motion.h1>
        
        <motion.p 
          className="text-xl md:text-2xl text-gray-300 mb-8 max-w-2xl mx-auto"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
             BACK TO THE FUTURE
        </motion.p>
        
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          <motion.button
            onClick={handleGetStarted}
            className="bg-cyber-blue text-cyber-dark px-8 py-3 rounded-lg text-lg font-semibold mr-4 hover:bg-cyber-blue-light transition-colors"
            whileHover={{ scale: 1.05, backgroundColor: '#0de5ff' }}
            whileTap={{ scale: 0.95 }}
          >
            Get Started
          </motion.button>
          
          <motion.button
            onClick={handleLearnMore}
            className="border-2 border-cyber-purple text-cyber-purple px-8 py-3 rounded-lg text-lg font-semibold hover:bg-cyber-purple/10 transition-colors"
            whileHover={{ scale: 1.05, borderColor: '#9333ea', color: '#9333ea' }}
            whileTap={{ scale: 0.95 }}
          >
            Learn More
          </motion.button>
        </motion.div>
      </motion.div>

      {/* Animated background elements */}
      <div className="absolute inset-0 z-0">
        {[...Array(20)].map((_, i) => (
          <motion.div
            key={i}
            className="absolute w-2 h-2 bg-cyber-blue rounded-full"
            initial={{
              x: Math.random() * window.innerWidth,
              y: Math.random() * window.innerHeight,
              opacity: Math.random(),
            }}
            animate={{
              y: [null, Math.random() * window.innerHeight],
              opacity: [null, Math.random()],
            }}
            transition={{
              duration: Math.random() * 5 + 5,
              repeat: Infinity,
              ease: "linear",
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default Hero;
