import React from 'react';
import { motion } from 'framer-motion';
import {
  Twitter as TwitterIcon,
  Facebook as FacebookIcon,
  LinkedIn as LinkedInIcon,
  GitHub as GitHubIcon,
  Instagram as InstagramIcon,
  Email as EmailIcon,
  Article as ArticleIcon,
  Business as BusinessIcon,
  Gavel as GavelIcon,
  Shield as ShieldIcon,
} from '@mui/icons-material';
import { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

const Footer = () => {
  const [modalContent, setModalContent] = useState(null);

  const socialLinks = [
    { icon: <TwitterIcon />, name: 'Twitter', url: 'https://twitter.com/timemachine' },
    { icon: <FacebookIcon />, name: 'Facebook', url: 'https://facebook.com/timemachine' },
    { icon: <LinkedInIcon />, name: 'LinkedIn', url: 'https://linkedin.com/company/timemachine' },
    { icon: <GitHubIcon />, name: 'GitHub', url: 'https://github.com/timemachine' },
    { icon: <InstagramIcon />, name: 'Instagram', url: 'https://instagram.com/timemachine' },
  ];

  const companyInfo = {
    about: {
      title: '关于我们',
      content: (
        <div className="space-y-4 text-gray-300">
          <p>Time Machine 是一个创新的区块链技术平台，致力于为用户提供安全、可靠的数字资产管理解决方案。</p>
          <h3 className="text-lg font-semibold text-cyber-blue">我们的愿景</h3>
          <p>打造全球领先的区块链技术平台，让数字资产管理变得更简单、更安全。</p>
          <h3 className="text-lg font-semibold text-cyber-blue">我们的使命</h3>
          <p>通过技术创新，为用户提供最佳的区块链解决方案，推动区块链技术的普及和发展。</p>
          <h3 className="text-lg font-semibold text-cyber-blue">核心价值观</h3>
          <ul className="list-disc list-inside">
            <li>创新驱动发展</li>
            <li>用户至上</li>
            <li>安全可靠</li>
            <li>开放协作</li>
          </ul>
        </div>
      )
    },
    news: {
      title: '新闻中心',
      content: (
        <div className="space-y-4 text-gray-300">
          <div className="border-b border-cyber-purple/30 pb-4">
            <h3 className="text-lg font-semibold text-cyber-blue">平台重大更新：新增高级数据分析功能</h3>
            <p className="text-sm text-gray-400">2024年3月15日</p>
            <p className="mt-2">我们很高兴地宣布，平台已完成新一轮功能更新，新增了高级数据分析工具...</p>
          </div>
          <div className="border-b border-cyber-purple/30 pb-4">
            <h3 className="text-lg font-semibold text-cyber-blue">Time Machine 获得新一轮融资</h3>
            <p className="text-sm text-gray-400">2024年2月28日</p>
            <p className="mt-2">Time Machine 完成 A 轮融资，将进一步加强产品研发和市场拓展...</p>
          </div>
        </div>
      )
    },
    terms: {
      title: '法律条款',
      content: (
        <div className="space-y-4 text-gray-300">
          <h3 className="text-lg font-semibold text-cyber-blue">1. 服务条款</h3>
          <p>通过访问和使用 Time Machine，您同意遵守这些条款和条件...</p>
          <h3 className="text-lg font-semibold text-cyber-blue">2. 用户责任</h3>
          <p>用户应对其账户下的所有活动负责，并同意不会...</p>
          <h3 className="text-lg font-semibold text-cyber-blue">3. 知识产权</h3>
          <p>Time Machine 及其内容受著作权法和其他知识产权法保护...</p>
          <h3 className="text-lg font-semibold text-cyber-blue">4. 免责声明</h3>
          <p>我们的服务按"现状"提供，不提供任何明示或暗示的保证...</p>
        </div>
      )
    },
    privacy: {
      title: '隐私政策',
      content: (
        <div className="space-y-4 text-gray-300">
          <h3 className="text-lg font-semibold text-cyber-blue">1. 信息收集</h3>
          <p>我们收集的信息包括但不限于：用户提供的个人信息、使用我们服务时自动生成的信息...</p>
          <h3 className="text-lg font-semibold text-cyber-blue">2. 信息使用</h3>
          <p>我们使用收集的信息来提供、维护和改进我们的服务，开发新的服务...</p>
          <h3 className="text-lg font-semibold text-cyber-blue">3. 信息安全</h3>
          <p>我们采用行业标准的安全措施来保护您的个人信息...</p>
          <h3 className="text-lg font-semibold text-cyber-blue">4. Cookie 使用</h3>
          <p>我们使用 Cookie 和类似技术来提供和支持我们的服务...</p>
        </div>
      )
    }
  };

  const companyLinks = [
    { icon: <BusinessIcon />, name: '关于我们', key: 'about' },
    { icon: <ArticleIcon />, name: '新闻中心', key: 'news' },
    { icon: <GavelIcon />, name: '法律条款', key: 'terms' },
    { icon: <ShieldIcon />, name: '隐私政策', key: 'privacy' },
  ];

  const handleOpenModal = (key) => {
    setModalContent(companyInfo[key]);
  };

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: { opacity: 1, y: 0 },
  };

  const renderQuickLinks = () => (
    <motion.div variants={itemVariants} className="space-y-4">
      <h3 className="text-xl font-bold text-cyber-blue">快速链接</h3>
      <ul className="space-y-2">
        {companyLinks.map((link) => (
          <li key={link.key}>
            <motion.button
              onClick={() => handleOpenModal(link.key)}
              className="text-gray-400 hover:text-cyber-blue flex items-center space-x-2 transition-colors w-full text-left"
              whileHover={{ x: 5 }}
            >
              {link.icon}
              <span>{link.name}</span>
            </motion.button>
          </li>
        ))}
      </ul>
    </motion.div>
  );

  return (
    <>
      <motion.footer
        initial="hidden"
        animate="visible"
        variants={containerVariants}
        className="bg-cyber-dark text-white py-12 mt-20 border-t border-cyber-purple/30"
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {/* Company Info */}
            <motion.div variants={itemVariants} className="space-y-4">
              <h3 className="text-xl font-bold text-cyber-blue">Time Machine</h3>
              <p className="text-gray-400">
                引领区块链技术创新，打造未来数字资产管理平台。
              </p>
              <div className="flex space-x-4">
                {socialLinks.map((link) => (
                  <motion.a
                    key={link.name}
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-400 hover:text-cyber-blue transition-colors"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    {link.icon}
                  </motion.a>
                ))}
              </div>
            </motion.div>

            {renderQuickLinks()}

            {/* Contact Info */}
            <motion.div variants={itemVariants} className="space-y-4">
              <h3 className="text-xl font-bold text-cyber-blue">联系我们</h3>
              <ul className="space-y-2">
                <li className="flex items-center space-x-2 text-gray-400">
                  <EmailIcon />
                  <span>support@timemachine.us.kg</span>
                </li>
                <li className="text-gray-400">
                  工作时间：周一至周五 9:00-18:00
                </li>
              </ul>
            </motion.div>

            {/* Newsletter */}
            <motion.div variants={itemVariants} className="space-y-4">
              <h3 className="text-xl font-bold text-cyber-blue">订阅通讯</h3>
              <p className="text-gray-400">
                订阅我们的通讯，获取最新的产品更新和行业新闻。
              </p>
              <form className="flex space-x-2">
                <input
                  type="email"
                  placeholder="输入您的邮箱"
                  className="flex-1 px-4 py-2 bg-cyber-dark/50 border border-cyber-purple/30 rounded-md text-white focus:outline-none focus:border-cyber-blue"
                />
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="px-4 py-2 bg-cyber-purple text-white rounded-md hover:bg-cyber-purple/80 transition-colors"
                >
                  订阅
                </motion.button>
              </form>
            </motion.div>
          </div>

          {/* Bottom Bar */}
          <motion.div
            variants={itemVariants}
            className="mt-12 pt-8 border-t border-cyber-purple/30 text-center text-gray-400"
          >
            <p>© {new Date().getFullYear()} Time Machine. All rights reserved.</p>
          </motion.div>
        </div>
      </motion.footer>

      {/* Modal Dialog */}
      <Dialog
        open={!!modalContent}
        onClose={() => setModalContent(null)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          className: "bg-cyber-dark border border-cyber-purple/30"
        }}
      >
        {modalContent && (
          <>
            <DialogTitle className="flex justify-between items-center text-cyber-blue border-b border-cyber-purple/30">
              {modalContent.title}
              <IconButton
                onClick={() => setModalContent(null)}
                className="text-gray-400 hover:text-cyber-blue"
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent className="mt-4">
              {modalContent.content}
            </DialogContent>
          </>
        )}
      </Dialog>
    </>
  );
};

export default Footer;
